import {useCurrencyFormatter, useExperiments, useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import {Accordion, Text, TextButton, Divider} from 'wix-ui-tpa/cssVars'
import {useStyles} from '@wix/tpa-settings/react'

import {getLongDate} from '../../../../utils/dateString'
import stylesParams from '../../stylesParams'
import {LayoutSize} from '../../../../components-shared/LayoutSizeProvider/types'
import {EXPERIMENTS} from '../../../../utils/constants'

import {useHooks} from './useHooks'
import {classes, st} from './ReservationDetailsSection.st.css'
import {reservationDetailsSectionDataHooks} from './constants'

interface Props {
  layoutSize: LayoutSize
}

export const ReservationDetailsSection: React.FC<Props> = ({layoutSize}) => {
  const {
    startDate,
    partySize,
    handleChangeReservationClick,
    regionalSettings,
    locationName,
    isManualApproval,
    isPaymentRuleActive,
    paymentRuleFeePerGuest,
    currency,
    feePerGuestWithCurrency,
  } = useHooks()
  const {t} = useTranslation()
  const styles = useStyles()
  const formattedDate = getLongDate(startDate, regionalSettings)
  const formatCurrency = useCurrencyFormatter()
  const {experiments} = useExperiments()
  const isPaymentRuleFlowEnabled = experiments.enabled(EXPERIMENTS.isPaymentRuleFlowEnabled)

  const isAccordion = layoutSize === LayoutSize.XS || layoutSize === LayoutSize.S

  const titleTag =
    styles.get(stylesParams.detailsSectionSubtitleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.detailsSectionSubtitleFont).htmlTag

  const totalPrice = paymentRuleFeePerGuest * partySize
  const totalPriceWithCurrency = currency
    ? formatCurrency({value: totalPrice, currency})
    : totalPrice

  const content = (
    <section className={classes.section}>
      {isAccordion ? null : (
        <Text className={classes.title} tagName={titleTag}>
          {t(
            isManualApproval
              ? 'uou-reservations.reservation-details.request-details.title'
              : 'uou-reservations.reservation-details.details.title',
          )}
        </Text>
      )}

      <Text className={st(classes.text, classes.dateText)} tagName="time">
        {formattedDate}
      </Text>
      <Text className={classes.text} tagName="p">
        {t('uou-reservations.shared.guests', {
          number: partySize,
        })}
      </Text>
      <Text className={classes.text} tagName="p">
        {locationName}
      </Text>

      {isPaymentRuleFlowEnabled && isPaymentRuleActive && (
        <div className={classes.paymentInfo}>
          <Divider className={classes.divider} />
          <Text className={classes.title} tagName={titleTag}>
            {t('uou-reservations.reservation-details.payment-details.title')}
          </Text>
          <div className={classes.fee}>
            <Text className={classes.text} tagName="p">
              {t('uou-reservations.shared.price-for-reserved-seats', {
                pricePerGuest: feePerGuestWithCurrency,
                guestNumber: partySize,
              })}
            </Text>

            <Text className={classes.text} tagName="p">
              {totalPriceWithCurrency}
            </Text>
          </div>
        </div>
      )}
      <Divider className={classes.divider} />
      <TextButton
        className={classes.button}
        onClick={handleChangeReservationClick}
        data-hook={reservationDetailsSectionDataHooks.changeReservation()}
      >
        {t('uou-reservations.reservation-details.details.change-cta')}
      </TextButton>
    </section>
  )

  return (
    <div id="tr-details" aria-flowto="tr-form" className={st(classes.root, {[layoutSize]: true})}>
      {isAccordion ? (
        <Accordion className={classes.accordion}>
          <Accordion.Item
            id="id"
            title={t(
              isManualApproval
                ? 'uou-reservations.reservation-details.request-details.title'
                : 'uou-reservations.reservation-details.details.title',
            ).replace(':', '')}
          >
            {content}
          </Accordion.Item>
        </Accordion>
      ) : (
        content
      )}
    </div>
  )
}
