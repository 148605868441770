import React, {useEffect} from 'react'

import {useReservationDetailsStorage} from '../../storage'
import {RequestStatus} from '../../../../utils/wrapRequest'

export const useHooks = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const {
    reservation,
    submitReservationStatus,
    getReservationLocationsStatus,
    getReservationStatus,
    apiRequestErrorDetails,
    goToNewReservation,
  } = useReservationDetailsStorage()

  useEffect(() => {
    if (
      submitReservationStatus === RequestStatus.FAILED ||
      getReservationLocationsStatus === RequestStatus.FAILED ||
      getReservationStatus === RequestStatus.FAILED
    ) {
      setIsModalOpen(true)
    }
  }, [submitReservationStatus, getReservationLocationsStatus, getReservationStatus])

  const onClose = () => {
    setIsModalOpen(false)
  }

  const onCtaButtonClick = () => {
    goToNewReservation(reservation)
  }

  return {isModalOpen, onClose, onCtaButtonClick, apiRequestErrorDetails}
}
