import {useEffect} from 'react'

import {useReservationDetailsStorage} from '../storage'
import {useGetLayoutSize} from '../../../components-shared/LayoutSizeProvider'
import {getShouldShowAutoApproveText} from '../../../utils/getShouldShowAutoApproveText'

export const useHooks = () => {
  const {reservation, isManualApproval, getCurrentMember, currentMember, approvalTextEditorState} =
    useReservationDetailsStorage()
  const {layoutSize} = useGetLayoutSize()

  useEffect(() => {
    getCurrentMember()
  }, [getCurrentMember])

  const shouldShowAutoApproveText = getShouldShowAutoApproveText(
    approvalTextEditorState,
    isManualApproval,
  )

  return {reservation, isManualApproval, layoutSize, currentMember, shouldShowAutoApproveText}
}
