import {SettingsParamsType} from '../../types/createStylesParams'
import {
  formSettingsParams,
  FormSettingsParams,
} from '../../components-shared/Settings/FormDesign/settingsParams'

import {textsSettingsParams, TextsSettingsParams} from './Settings/Tabs/Texts/settingsParams'

export type SettingsParams = FormSettingsParams & TextsSettingsParams

export const settingsParams: SettingsParamsType<SettingsParams> = {
  ...formSettingsParams,
  ...textsSettingsParams,
}

export default settingsParams
