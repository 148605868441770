import {useEffect, useState} from 'react'

import {useReservationDetailsStorage} from '../../storage'

let timeout: NodeJS.Timeout

export const useHooks = () => {
  const {reservation} = useReservationDetailsStorage()
  const diffSec = (Date.now() - +new Date(reservation?.createdDate ?? new Date())) / 1000
  const initialTimeSec = 10 * 60 - Math.floor(Math.min(Math.max(diffSec, 0), 10 * 60))
  const [leftSec, setLeftSec] = useState(initialTimeSec)

  useEffect(() => {
    timeout = setTimeout(() => {
      const nextValue = Math.max(leftSec - 1, 0)
      setLeftSec(nextValue)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  })

  return {
    reservation,
    leftSec,
  }
}
