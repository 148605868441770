import {StylesParamsType} from '../../types/createStylesParams'
import {
  buttonDefaultStylesParams,
  buttonHoverStylesParams,
  ButtonDefaultStylesParams,
  ButtonHoverStylesParams,
} from '../../components-shared/Settings/Button/stylesParams'
import {
  formBoxStylesParams,
  formLineStylesParams,
  FormBoxStylesParams,
  FormLineStylesParams,
} from '../../components-shared/Settings/FormDesign/stylesParams'

import {generalStyleParams, GeneralStylesParams} from './Settings/Tabs/Design/General/stylesParams'
import {layoutStyleParams, LayoutStylesParams} from './Settings/Tabs/Layout/stylesParams'
import {
  DetailsSectionStylesParams,
  detailsSectionStylesParams,
} from './Settings/Tabs/Design/Details/stylesParams'

type StylesParams = GeneralStylesParams &
  DetailsSectionStylesParams &
  FormBoxStylesParams &
  FormLineStylesParams &
  ButtonDefaultStylesParams &
  ButtonHoverStylesParams &
  LayoutStylesParams

export const stylesParams: StylesParamsType<StylesParams> = {
  ...generalStyleParams,
  ...detailsSectionStylesParams,
  ...formBoxStylesParams,
  ...formLineStylesParams,
  ...buttonDefaultStylesParams,
  ...buttonHoverStylesParams,
  ...layoutStyleParams,
}

export default stylesParams
