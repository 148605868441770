import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Button, Dialog, Text, TextButton} from 'wix-ui-tpa/cssVars'
import {
  ReservationLocation,
  PrivacyPolicy,
  TermsAndConditions,
} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {classes} from './Form.st.css'

interface PolicyLinksProps {
  reservationLocation?: ReservationLocation
}

export const PolicyLinks: React.FC<PolicyLinksProps> = ({reservationLocation}) => {
  const {t} = useTranslation()
  const isPoliciesEnabled = reservationLocation?.configuration?.reservationForm?.policiesEnabled
  const isTermsEnabled =
    reservationLocation?.configuration?.reservationForm?.termsAndConditions?.enabled
  const isPrivacyEnabled =
    reservationLocation?.configuration?.reservationForm?.privacyPolicy?.enabled

  if (!isPoliciesEnabled || (!isTermsEnabled && !isPrivacyEnabled)) {
    return null
  }

  return (
    <div className={classes.policyLinksWrapper}>
      <Text className={classes.policyLinksText} tagName="p">
        {t('uou-reservations.reservation-details.terms-privacy.checkbox')}
        &nbsp;
        {isTermsEnabled ? (
          <PrivacyButton
            {...reservationLocation?.configuration?.reservationForm?.termsAndConditions}
            buttonText={t('uou-reservations.reservation-details.terms-conditions')}
          />
        ) : null}
        {isTermsEnabled && isPrivacyEnabled ? (
          <>&nbsp;{t('uou-reservations.reservation-details.terms-privacy.and')}&nbsp;</>
        ) : null}
        {isPrivacyEnabled ? (
          <PrivacyButton
            {...reservationLocation?.configuration?.reservationForm?.privacyPolicy}
            buttonText={t('uou-reservations.reservation-details.privacy-policy')}
          />
        ) : null}
      </Text>
    </div>
  )
}

interface PrivacyButtonProps extends TermsAndConditions, PrivacyPolicy {
  buttonText: string
}

const PrivacyButton: React.FC<PrivacyButtonProps> = (props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  if (props.text) {
    return (
      <>
        <TextButton
          className={classes.policyLink}
          onClick={() => {
            setIsModalOpen(true)
          }}
        >
          {props.buttonText}
        </TextButton>
        <PrivacyDialog
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false)
          }}
          text={props.text}
        />
      </>
    )
  }

  return (
    <TextButton
      as="a"
      className={classes.policyLink}
      href={props.url?.url ?? ''}
      onClick={(e) => {
        e.preventDefault()
        window.open(props.url?.url ?? '', '_blank')
      }}
    >
      {props.buttonText}
    </TextButton>
  )
}

interface PrivacyDialogProps {
  isOpen: boolean
  text: string
  onClose(): void
}

const PrivacyDialog: React.FC<PrivacyDialogProps> = (props) => {
  const {t} = useTranslation()

  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={props.onClose}
      className={classes.dialogElement}
      contentClassName={classes.dialogContentWrapper}
      wiredToSiteColors={true}
    >
      <div>
        <Text tagName="p" className={classes.dialogContent}>
          {props.text}
        </Text>
        <Button onClick={props.onClose} className={classes.dialogButton}>
          {t('uou-reservations.reservation-details.terms-privacy.close')}
        </Button>
      </div>
    </Dialog>
  )
}
