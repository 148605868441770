import {dataHooks} from '@wix/data-hooks'

import {LayoutBreakpointsConfig} from '../../../components-shared/LayoutSizeProvider/types'

export const LAYOUT_BREAKPOINTS_START: LayoutBreakpointsConfig = {
  XS: 280,
  S: 441,
  M: 848,
  L: 1201,
}

export const reservationDetailsDataHooks = dataHooks<{
  root: {}
}>('reservation-details')
