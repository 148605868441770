import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {dataHooks} from '@wix/data-hooks'

import {getFormUICustomFieldsFromArea} from '../../../../utils/reservationLocation'

export const reservationDetailsFormDataHooks = dataHooks<{
  root: {}
  firstName: {}
  lastName: {}
  phoneNumber: {}
  email: {}
  completeReservation: {}
}>('reservation-details-form')

export type FormUIField<T> = {
  value: T
  isRequired: boolean
  valid: boolean
}

export interface FormUI {
  firstName: FormUIField<string>
  lastName: FormUIField<string>
  phoneNumber: FormUIField<string>
  email: FormUIField<string>
  customFields: {
    [key: string]: FormUIField<string>
  }
}

export type BasicFormUI = Omit<FormUI, 'customFields'>

export const FIRST_NAME_LENGTH_MAX = 50
export const LAST_NAME_LENGTH_MAX = 50
export const EMAIL_LENGTH_MAX = 100
export const CUSTOM_FIELD_LENGTH_MAX = 100

export const getFormInitialState = (reservationLocation?: ReservationLocation): FormUI => ({
  firstName: {
    value: '',
    isRequired: true,
    valid: true,
  },
  lastName: {
    value: '',
    isRequired: reservationLocation?.configuration?.reservationForm?.lastNameRequired ?? false,
    valid: true,
  },
  phoneNumber: {
    value: '',
    isRequired: true,
    valid: true,
  },
  email: {
    value: '',
    isRequired: reservationLocation?.configuration?.reservationForm?.emailRequired ?? false,
    valid: true,
  },
  customFields:
    getFormUICustomFieldsFromArea(
      reservationLocation?.configuration?.reservationForm?.customFieldDefinitions,
    ) ?? {},
})
