import {isValidPhoneNumber} from 'react-phone-number-input'

import {FormUI, FormUIField} from './constants'

type Validator = {
  firstName: (firstName: FormUI['firstName'], fields: Omit<FormUI, 'customFields'>) => boolean
  lastName: (lastName: FormUI['lastName'], fields: Omit<FormUI, 'customFields'>) => boolean
  phoneNumber: (phoneNumber: FormUI['phoneNumber'], fields: Omit<FormUI, 'customFields'>) => boolean
  email: (email: FormUI['email'], fields: Omit<FormUI, 'customFields'>) => boolean
  customField: (value: FormUI['customFields'][string]) => boolean
}

export const LIMITS = {
  commonString: {max: 200},
}

const EMAIL_REGEXP =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

const validateTextField = (field: FormUIField<string>) => {
  const trimmedValue = field?.value?.trim()

  if (!field.isRequired && !field?.value) {
    return true
  }

  return !!trimmedValue.length && trimmedValue.length < LIMITS.commonString.max
}

const validator: Validator = {
  firstName: validateTextField,
  lastName: validateTextField,
  customField: validateTextField,
  phoneNumber: (phoneNumber) => isValidPhoneNumber(phoneNumber?.value),
  email: (field) => {
    if (!field.isRequired && !field.value) {
      return true
    }

    return EMAIL_REGEXP.test(field.value) && field.value?.length < LIMITS.commonString.max
  },
}

export const validateForm = ({
  customFields,
  ...rest
}: FormUI): {
  valid: boolean
  state: FormUI
  validFieldsNumber: number
  invalidFieldsNumber: number
} => {
  let valid = true
  let state: Partial<FormUI> = {}
  let validFieldsNumber = 0
  let invalidFieldsNumber = 0

  Object.keys(rest).forEach((field) => {
    const result = validator[field](rest[field], rest) as boolean

    state = {
      ...state,
      [field]: {
        ...rest[field],
        valid: result,
      },
    }

    if (!result) {
      valid = false
      invalidFieldsNumber += 1
    } else {
      validFieldsNumber += 1
    }
  })

  state.customFields = state.customFields ?? {}

  Object.keys(customFields).forEach((id) => {
    const result = validator.customField(customFields[id]) as boolean

    state.customFields = {
      ...state.customFields,
      [id]: {
        ...customFields[id],
        valid: result,
      },
    }

    if (!result) {
      valid = false
      invalidFieldsNumber += 1
    } else {
      validFieldsNumber += 1
    }
  })

  return {
    valid,
    state: state as FormUI,
    validFieldsNumber,
    invalidFieldsNumber,
  }
}
